import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/core/services/customer.service';
import { map, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  public isValidated = false;
  public loading = true;

  constructor(private customerService: CustomerService, private route: ActivatedRoute) {}
  tenant: String = "";

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.tenant = data.tenant;
    })
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        switchMap((params) => this.customerService.validateEmail(params.id, params.key))
      )
      .subscribe(
        (resp) => {
          this.isValidated = true;
          this.loading = false;
        },
        (error) => {
          this.isValidated = false;
          this.loading = false;
        }
      );
  }

  ngOnDestroy(): void {}
}

<h1 class="text-3xl mb-8" i18n>Download your app</h1>

<div class="flex row justify-center justify-items-center content-center place-content-center gap-10">
  <button class="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded-full  inline-flex items-center" (click)="goToUrl('iOS')">
    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
    Apple App Store
  </button>
  <button class="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded-full  inline-flex items-center" (click)="goToUrl('Android')">
    <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
    Google Play Store
  </button>
</div>

export const environment = {
  production: false,
  api: {
    url: 'https://api-dev.angell.bike/mini',
  },
  tenant: {
    name: 'MINI',
    label: 'MINI E-Bike',
    logo: 'assets/MINI/logo_mini.svg',
    favico: 'assets/MINI/favicon.ico'
  },
  appstorelink: 'https://apps.apple.com/us/app/mini-e-bike/id6462420146',
  playstorelink: 'https://play.google.com/store/apps/details?id=com.angellmobility.oxford.ebikes',
};

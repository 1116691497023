import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private apiService: ApiService) {}

  public validateEmail(userId: string, key: string): Observable<void> {
    return this.apiService.patch('/customers/activate', {
      activationKey: key,
      customerId: userId,
    });
  }

  public changePassword(password: string, key: string): Observable<void> {
    return this.apiService.patch('/customers/password/forgot', {
      password: password,
      passwordResetKey: key,
    });
  }
}

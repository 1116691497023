import {Component, Inject, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Tenant} from "../../core/Tenant";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  tenant?: Tenant;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }
  ngOnInit(): void {
    this.tenant = new Tenant(environment.tenant);
    const os = this.getOS();

    if (os != null) {
      this.goToUrl(os);
    }
  }

  getOS() {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return null;
  }

  goToUrl(os: string): void {
    switch (os) {
      case "Android":
        this.document.location.href = environment.playstorelink;
        break;
      case "iOS":
        this.document.location.href = environment.appstorelink;
        break;
    }
  }

}

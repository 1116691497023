import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Tenant} from "./core/Tenant";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public year: number = new Date().getFullYear();

  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');
  tenant?: Tenant;

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.tenant = new Tenant(environment.tenant);
    // @ts-ignore
    this.favIcon.href = this.tenant.favico;
    this.titleService.setTitle(this.tenant.label);
  }
}



import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Tenant} from "../../core/Tenant";

@Component({
  selector: 'app-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.scss']
})
export class OpenComponent implements OnInit {
  tenant?: Tenant;

  ngOnInit(): void {
    this.tenant = new Tenant(environment.tenant);
  }

}

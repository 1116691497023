export class Tenant {
  name: string;
  label: string;
  logo: string;
  favico: string;

  public constructor(data: any) {
    this.name = data.name;
    this.label = data.label;
    this.logo = data.logo;
    this.favico = data.favico;
  }
}

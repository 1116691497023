import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {OpenComponent} from './pages/open/open.component';
import {VerifyEmailComponent} from './pages/verify-email/verify-email.component';
import {DownloadComponent} from "./pages/download/download.component";

const routes: Routes = [
  {
    path: 'pro/account/update-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
  },
  {
    path: 'account/verify-email',
    pathMatch: 'full',
    component: VerifyEmailComponent,
  },
  {
    path: 'account/forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
  },
  {
    path: 'open',
    pathMatch: 'full',
    component: OpenComponent,
  },
  {
    path: 'download',
    pathMatch: 'full',
    component: DownloadComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

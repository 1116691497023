<h1 class="text-3xl mb-8" i18n>Change your password</h1>

<div *ngIf="isSubmitted && isPasswordChanged" class="text-green-600 mb-8">
  <span i18n>Your password has been changed.</span>
</div>

<div *ngIf="isSubmitted && !isPasswordChanged" class="text-red-400 mb-8">
  <span i18n>An error occurred, try it later.</span>
</div>

<form *ngIf="!isSubmitted" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
  <div class="mb-6">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="new-password" i18n>New Password:</label>
    <input
      class="rounded-full w-full"
      id="new-password"
      type="password"
      formControlName="newPassword"
      autocomplete="new-password"
      [ngClass]="{
        'border-red-400':
          forgotPasswordForm.controls.newPassword.invalid && forgotPasswordForm.controls.newPassword.dirty
      }"
    />
    <div *ngIf="forgotPasswordForm.controls.newPassword.invalid && forgotPasswordForm.controls.newPassword.dirty">
      <div *ngIf="forgotPasswordForm.controls.newPassword.errors?.required" class="text-red-400 mb-2 mt-1" i18n>New password is required.</div>
      <div
        *ngIf="
          forgotPasswordForm.controls.newPassword.errors?.minlength ||
          forgotPasswordForm.controls.newPassword.errors?.maxlength
        "
        class="text-red-400 mb-2 mt-1" i18n>Your password must be at least 8 characters long.</div>
    </div>
  </div>

  <div class="mb-8">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="confirm-password" i18n>Confirm Password:</label>
    <input
      class="rounded-full w-full"
      id="confirm-password"
      type="password"
      formControlName="confirmPassword"
      [ngClass]="{
        'border-red-400':
          forgotPasswordForm.controls.confirmPassword.invalid && forgotPasswordForm.controls.confirmPassword.dirty
      }"
    />
    <div
      *ngIf="forgotPasswordForm.controls.confirmPassword.invalid && forgotPasswordForm.controls.confirmPassword.dirty"
    >
      <div *ngIf="forgotPasswordForm.controls.confirmPassword.errors?.required" class="text-red-400 mb-2 mt-1" i18n>Confirm password is required.</div>
      <div
        *ngIf="
          forgotPasswordForm.controls.confirmPassword.errors?.minlength ||
          forgotPasswordForm.controls.confirmPassword.errors?.maxlength
        "
        class="text-red-400 mb-2 mt-1" i18n>Your password must be at least 8 characters long.</div>
      <div
        *ngIf="forgotPasswordForm.controls.confirmPassword.errors?.passwordNotEqual"
        class="text-red-400 mb-2 mt-1" i18n>The Password and Confirm Password fields must be equal.</div>
    </div>
  </div>

  <div class="">
    <button
      class="bg-black hover:enabled:bg-gray-800 w-full rounded-full text-white p-4 disabled:opacity-50"
      type="submit"
      [disabled]="!forgotPasswordForm.valid" i18n>Change password</button>
  </div>
</form>

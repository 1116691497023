import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {BrowserModule, Title} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { OpenComponent } from './pages/open/open.component';
import {DownloadComponent} from "./pages/download/download.component";

@NgModule({ declarations: [AppComponent, ForgotPasswordComponent, VerifyEmailComponent, OpenComponent, DownloadComponent],
    bootstrap: [AppComponent], imports: [BrowserModule, AppRoutingModule, ReactiveFormsModule], providers: [
        Title,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { CustomerService } from 'src/app/core/services/customer.service';

function passwordValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.get('newPassword')?.value || !control.get('confirmPassword')?.value) {
    return null;
  }

  if (control.get('newPassword')?.value !== control.get('confirmPassword')?.value) {
    return { passwordNotEqual: true };
  }

  return null;
}

function checkPasswordValidator(control: AbstractControl): ValidationErrors | null {
  control.parent?.get;
  if (!control.parent?.get('newPassword')?.value || !control.value) {
    return null;
  }

  if (control.parent?.get('newPassword')?.value !== control.value) {
    return { passwordNotEqual: true };
  }

  return null;
}

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  private key: string = '';
  public isPasswordChanged = false;
  public isSubmitted = false;

  forgotPasswordForm = this.fb.group(
    {
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(100)])],
      confirmPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(100),
          checkPasswordValidator,
        ]),
      ],
    } /*,
    {
      validators: passwordValidator,
    }*/
  );

  constructor(private fb: UntypedFormBuilder, private customerService: CustomerService, private route: ActivatedRoute) {}

  tenant: String = "";

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => (this.key = params.key));
    this.route.data.subscribe(data => {
      this.tenant = data.tenant;
    })
  }

  onSubmit(): void {
    this.customerService
      .changePassword(this.forgotPasswordForm.controls.newPassword.value, this.key)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isSubmitted = true))
      )
      .subscribe(
        (resp) => {
          this.isPasswordChanged = true;
          this.forgotPasswordForm.reset();
        },
        (error) => {
          this.isPasswordChanged = false;
        }
      );
  }
}

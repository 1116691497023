import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export const serialize = (obj: { [key: string]: string | number }) => {
  const str: string[] = [];

  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }

  return str.join('&');
};

export interface RequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private base = environment.api.url;

  constructor(private http: HttpClient) {}

  getUrl(path: string): string {
    if (path.charAt(0) === '/') {
      path = path.substr(1, path.length);
    }

    return `${this.base}/${path}`;
  }

  processBody(body: any, options: RequestOptions): any {
    if (options.headers !== undefined && options.headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
      return serialize(body);
    }

    return body;
  }

  getOptions(options?: RequestOptions): RequestOptions {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (!options) {
      options = {};
    }

    if (options.headers) {
      const hdrs = options.headers;

      headers.keys().forEach((key) => {
        if (!hdrs.has(key)) {
          const val = headers.getAll(key);
          if (val !== null) {
            options!.headers = hdrs.set(key, val);
          }
        }
      });
    } else {
      options.headers = headers;
    }

    options.headers.keys().forEach((key) => {
      const keys = options!.headers!.getAll(key);
      if (keys !== null) {
        keys.forEach((val) => {
          if ((val as any) === false || val === '') {
            options!.headers = options!.headers!.delete(key);
          }
        });
      }
    });

    return options;
  }

  /**
   * Performs a request with `get` http method.
   */
  get<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.http.get<T>(this.getUrl(path), this.getOptions(options));
  }

  /**
   * Performs a request with `post` http method.
   */
  post<T>(path: string, body: any, options?: RequestOptions): Observable<T> {
    options = this.getOptions(options);

    return this.http.post<T>(this.getUrl(path), this.processBody(body, options), options);
  }

  /**
   * Performs a request with `put` http method.
   */
  put<T>(path: string, body: any, options?: RequestOptions): Observable<T> {
    options = this.getOptions(options);

    return this.http.put<T>(this.getUrl(path), this.processBody(body, options), options);
  }

  /**
   * Performs a request with `delete` http method.
   */
  delete<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.http.delete<T>(this.getUrl(path), this.getOptions(options));
  }

  /**
   * Performs a request with `patch` http method.
   */
  patch<T>(path: string, body: any, options?: RequestOptions): Observable<T> {
    options = this.getOptions(options);

    return this.http.patch<T>(this.getUrl(path), this.processBody(body, options), options);
  }
}
